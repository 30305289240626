import $ from 'jquery'
import moment from 'moment-timezone'
import React from 'react'
import ReactDom from 'react-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DATE_FORMAT } from 'lib/const'

class DatePickerInput extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      value: props.initialValue ? moment(props.initialValue) : null
    }
  }

  handleChange = value => {
    this.setState({ value })
  }

  render () {
    return (
      <DatePicker
        selected={this.state.value}
        onChange={this.handleChange}
        dateFormat={DATE_FORMAT}
        {...this.props}
      />
    )
  }
}

export const render = () => {
  $('[data-datepicker]').each((i, el) => {
    const input = $(el).find('input')

    ReactDom.render(
      <DatePickerInput
        name={input.prop('name')}
        locale={window.locale}
        initialValue={input.val()}
      />,
      el
    )
  })
}
