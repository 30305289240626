// Unobtrusive JS for the whole application.
// Stuff you would normally add to `/app/assets/javascripts/` should be initialized here
// and defined in `/app/javascript/ujs`

import $ from 'jquery'
import Rails from 'rails-ujs'
import Turbolinks from 'turbolinks'
import 'cocoon-js'

import Sidebar from 'ujs/Sidebar'
import Flash from 'ujs/Flash'
import Header from 'ujs/Header'
import FilterAction from 'ujs/FilterAction'
import BtnConfirm from 'ujs/BtnConfirm'
import ChangeoverForm from 'ujs/ChangeoverForm'
import ProductForm from 'ujs/ProductForm'
import IndicatorThresholdChart from 'ujs/IndicatorThresholdChart'
import Modal, { RENDER_EVENT } from 'ujs/Modal'
import { render as renderDatePickers } from 'ujs/DatePicker'
import { render as renderColorPickers } from 'ujs/ColorPicker'
import ServerTime from 'ujs/ServerTime'
import momentInit from 'lib/momentInit'

Rails.start()
Turbolinks.start()
momentInit()

BtnConfirm.start()
ChangeoverForm.start()
ProductForm.start()
Sidebar.start()
Flash.start()
Header.start()
FilterAction.start()
Modal.start('body')
ServerTime.start()
IndicatorThresholdChart.start()

$(window).on(`turbolinks:load ${RENDER_EVENT}`, e => {
  renderDatePickers()
  renderColorPickers()
})
