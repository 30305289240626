import $ from 'jquery'
import moment from 'moment-timezone'

const FORMAT = 'HH:mm zz'
const MINUTE_IN_MILLISECONDS = 60 * 1000

let nextUpdate

function now () {
  return moment.tz(window.timezone)
}

function timeUntillNextMinute () {
  return MINUTE_IN_MILLISECONDS - now().valueOf() % MINUTE_IN_MILLISECONDS
}

const ServerTime = {
  start: () => {
    $(window).on('turbolinks:load', e => {
      clearTimeout(nextUpdate)
      ServerTime.hydrate()
    })
  },

  hydrate: () => {
    nextUpdate = setTimeout(ServerTime.update, timeUntillNextMinute())
  },

  update: () => {
    $('[data-server-time]').html(now().format(FORMAT))
    ServerTime.hydrate()
  }
}

export default ServerTime
