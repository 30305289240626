import $ from 'jquery'

export default {
  start: function () {
    $(window).on('turbolinks:load', e => {
      const container = $('#indicator-threshold-chart')

      if (!window.google || !container) {
        return
      }

      window.google.charts.load('current', { packages: ['corechart'] })
      window.google.charts.setOnLoadCallback(this.drawChart.bind(this))
    })
  },
  drawChart: function () {
    const container = document.getElementById('indicator-threshold-chart')

    if (!container) {
      return
    }

    const chart = new window.google.visualization.LineChart(
      container
    )

    const formatDate = new window.google.visualization.DateFormat({ pattern: 'MM-YYYY' })

    const rawData = $('#indicator-threshold-chart').data('chart-data')
    rawData.forEach((el, idx) => {
      if (idx === 0) {
        return
      }

      rawData[idx][0] = new Date(Date.parse(el[0]))
    })

    const dataTable = window.google.visualization.arrayToDataTable(rawData)
    formatDate.format(dataTable, 0)

    chart.draw(dataTable, this.chartOptions)
  },
  chartOptions: {
    chartArea: {
      left: 20
    },
    backgroundColor: 'transparent',
    hAxis: {
      textStyle: { fontSize: 10, color: '#999999' },
      gridlines: { color: '#eee', count: 14 },
      textPosition: 'out',
      baselineColor: '#eee',
      slantedText: false,
      maxTextLines: 1,
      maxAlternation: 1,
      format: 'MM-YYYY'
    },
    vAxis: {
      textStyle: { fontSize: 10, color: '#999999' },
      gridlines: { color: '#eee' },
      textPosition: 'in',
      baselineColor: '#aaa',
      minValue: 0,
      maxValue: 120
    },
    focusTarget: 'category',
    pointSize: 3,
    legend: { position: 'in', alignment: 'end', textStyle: { fontSize: 11 } },
    aggregationTarget: 'auto'
  }
}
