import $ from 'jquery'
import Cookies from 'js-cookie'
import SimpleBar from 'simplebar'
import 'simplebar/dist/simplebar.css'

const COOKIE_NAME = 'sidebar-collapsed'

const collapse = e => {
  if (e !== undefined) {
    e.preventDefault()
  }

  $('.sidebar').toggleClass('sidebar--collapsed')
  $('.content').toggleClass('content--expanded')

  Cookies.set(COOKIE_NAME, $('.sidebar').hasClass('sidebar--collapsed'))
}

const setupCollapse = () => {
  $('#sidebar-toggle').click(collapse)
}

const setupScroll = () => {
  new SimpleBar($('.sidebar .sidebar__scroll')[0])
  setTimeout(() => {
    const selectedSection = document.querySelector('.sidebar__item--selected')
    if (!selectedSection) {
      return
    }

    selectedSection.scrollIntoView()
  }, 100)
}

export default {
  start: () => {
    $(window).on('turbolinks:load', e => {
      setupCollapse()
      setupScroll()
    })
  },
  setupCollapse
}
