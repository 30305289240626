import $ from 'jquery'

export default {
  start: () => {
    $(window).on('turbolinks:visit', () => {
      $('.header__loader').show()
    })

    $(window).on('turbolinks:load', () => {
      $('.header__loader').hide()
    })
  }
}
