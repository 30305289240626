import $ from 'jquery'

const overlayTemplate = '<div class="modal__overlay modal__overlay--after-open"></div>'
const modalTemplate = '<div class="modal modal--after-open"></div>'
let renderParentMatcher

export const RENDER_EVENT = 'Modal:render'

const render = (html, width = null) => {
  let overlay = $(overlayTemplate)
  let modal = $(modalTemplate)

  modal.append(html)
  modal.css({ minWidth: width && width + 'px' })
  overlay.append(modal)
  $(renderParentMatcher).append(overlay)
  $(renderParentMatcher).trigger(RENDER_EVENT)

  overlay.click(e => {
    if (e.target === overlay.get(0)) {
      overlay.remove()
    }
  })
}

const Modal = {
  start: (parent) => {
    renderParentMatcher = parent
    window.Modal = Modal
  },
  stop: () => {
    renderParentMatcher = null
    delete window.Modal
  },
  render,
  closeAll: () => {
    $(renderParentMatcher).find('.modal__overlay').remove()
  }
}

export default Modal
