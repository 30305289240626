import $ from 'jquery'
import moment from 'moment-timezone'
import React from 'react'
import ReactDom from 'react-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DATE_FORMAT } from 'lib/const'

class DateFilter extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      fromDate: props.from.value ? moment(props.from.value) : null,
      toDate: props.to.value ? moment(props.to.value) : null
    }
  }

  handleFromChange = fromDate => {
    this.setStateAndChange({ fromDate })
  }

  handleToChange = toDate => {
    this.setStateAndChange({ toDate })
  }

  setStateAndChange = newState => {
    const { onChange } = this.props
    this.setState(newState, onChange)
  }

  render () {
    const { locale, from, to } = this.props
    const { fromDate, toDate } = this.state
    const common = {
      locale,
      dateFormat: DATE_FORMAT,
      startDate: fromDate,
      endDate: toDate
    }

    return (
      <div>
        <DatePicker
          selected={this.state.fromDate}
          onChange={this.handleFromChange}
          name={from.name}
          selectsStart
          {...common}
        />
        <DatePicker
          selected={this.state.toDate}
          onChange={this.handleToChange}
          name={to.name}
          selectsEnd
          {...common}
        />
    </div>
    )
  }
}

const renderDateFilters = () => {
  $('.date-filter').each((i, el) => {
    const from = $(el).find('.datepicker-from-date')
    const to = $(el).find('.datepicker-to-date')

    ReactDom.render(
      <DateFilter
        from={{ name: from.prop('name'), value: from.val() }}
        to={{ name: to.prop('name'), value: to.val() }}
        locale={window.locale}
        onChange={() => $(el).closest('form.filter-form').submit()}
      />,
      el
    )
  })
}

const bindAutosubmit = () => {
  $('.filter-form').find('input, select, textarea')
    .not('.not-submittable')
    .change(e => {
      $(e.target).closest('form').submit()
    })
}

const bindControls = () => {
  $('.filter-form')
    .find('.clear-single-filter')
      .click((e) => {
        $(e.target).closest('th')
          .find('input, select, textarea').val('').change()
      })
}

export default {
  start: () => {
    $(window).on('turbolinks:load', e => {
      bindAutosubmit()
      bindControls()
      renderDateFilters()
    })
  }
}
