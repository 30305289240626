import $ from 'jquery'

const FADE_OUT_DURATION = 300
const FADE_OUT_DELAY = 3000

function showFlash (message, type) {
  let flash = $(`<div class="flash flash--shaking flash--${type}"></div>`)
  flash.text(message)
  $('body').append(flash)
}

const Flash = {
  start: () => {
    $(window).on('turbolinks:load', e => {
      Flash.hide()
    })
  },

  hide: () => {
    setTimeout(
      () => $('.flash').fadeOut(FADE_OUT_DURATION),
      FADE_OUT_DELAY
    )

    $('.flash').click(() => $('.flash').fadeOut(FADE_OUT_DURATION))
  },

  alert: (message) => {
    showFlash(message, 'alert')
    Flash.hide()
  },

  notice: (message) => {
    showFlash(message, 'notice')
    Flash.hide()
  }
}

export default Flash
