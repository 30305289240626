import $ from 'jquery'

const lockable = [
  '.nested-fields select',
  '.nested-fields input',
  '.nested-fields a',
  'a.add_fields'
].join(', ')

export default {
  start: () => {
    $(window).on('turbolinks:load', e => {
      var locationsSelect = document.getElementById('product_location_ids')
      if (locationsSelect) {
        locationsSelect.onchange = (event) => {
          Array.from(document.querySelectorAll(lockable))
            .forEach((el) => el.disabled = true)
        }
      }
    })
  }
}
