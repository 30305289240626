import $ from 'jquery'

export default {
  start: () => {
    $(window).on('turbolinks:load', e => {
      $('.changeover-form__input').change((event) => {
        $(event.target)
          .closest('tr')
          .find('.changeover-form__submit')
          .addClass('btn--success')
      })

      const $form = $('form#update_all_form, form[id^="edit_project_changeover"]')
      $form.on('ajax:success', (event, data, status, xhr) => {
        setTimeout(
          () => $(event.target).closest('tr').find('.changeover-form__submit').removeClass('btn--success'),
          500
        )
      })

      $form.on('ajax:error', (event, xhr, status, error) => {
        setTimeout(
          () => $(event.target).closest('tr').find('.changeover-form__submit').removeClass('btn--success'),
          500
        )
        $(event.target)
          .closest('tr')
          .find('form:last')
          .append('<div class="input__error">Error!</div>')
      })
    })
  }
}
