import $ from 'jquery'
import React from 'react'
import ReactDom from 'react-dom'
import PropTypes from 'prop-types'
import { ChromePicker } from 'react-color'

class ColorPicker extends React.Component {
  static propTypes = {
    color: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    inputName: PropTypes.string.isRequired,
    inputId: PropTypes.string.isRequired
  }

  constructor (props) {
    super(props)

    this.state = {
      color: props.color
    }
  }

  handleChangeComplete = (color) => {
    if (color.hex === undefined) {
      color = ''
    } else {
      color = color.hex.replace('#', '')
    }
    this.setState({ color: color })
  }

  render () {
    return (
      <React.Fragment>
        <label className='string optional' htmlFor={this.props.inputId}>
          {this.props.labelText}
        </label>
        <input
          className='string optional'
          type='text'
          value={this.state.color}
          onChange={this.handleChangeComplete}
          name={this.props.inputName}
          id={this.props.inputId}
        />
        <ChromePicker
          color={this.state.color}
          onChangeComplete={this.handleChangeComplete}
        />
      </React.Fragment>
    )
  }
}

export const render = () => {
  $('.color-picker').each((i, el) => {
    const input = $(el).find('input')
    const label = $(el).find('label')

    ReactDom.render(
      <ColorPicker
        color={input.val()}
        labelText={label.html()}
        inputId={input.attr('id')}
        inputName={input.attr('name')}
      />,
      el
    )
  })
}
