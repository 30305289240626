import $ from 'jquery'

export default {
  start: () => {
    $(window).on('turbolinks:load', e => {
      $('.btn--confirm').each((i, el) => {
        let btnYes = $(el).clone().html('<i class="fa fa-check fa-fw"></i>')
        const smallButton = $(el).hasClass('btn--small')
        let btnNo = $(`<a href="#" class="btn ${smallButton ? 'btn--small' : ''}"><i class="fa fa-times fa-fw"></i></a>`)

        $(el).prop('href', '#')
          .removeData('method').removeAttr('data-method')
          .removeData('remote').removeAttr('data-remote')
        $(el).unbind('click')

        $(el).click((e) => {
          e.preventDefault()
          $(el).hide().after(btnNo).after(btnYes)

          $(btnNo).click((e) => {
            e.preventDefault()
            btnNo.remove()
            btnYes.remove()
            $(el).show()
          })
        })
      })
    })
  }
}
